#root,
body,
.App {
  height: 100vh;
  width: 100vw;
}
body {
  margin: 0;
}
/* Réinitialiser le style de fond des champs de formulaire */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f0f0f0 inset !important;
  -webkit-text-fill-color: #002855 !important;
}
